@mixin gradient-text($gradient, $bg : 'light') {
  @supports(mix-blend-mode: lighten) {
    display: inline-block;
    position: relative;
    
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      
      background: unquote($gradient);
      pointer-events: none;
    }

    @if ($bg == 'light') {
      color: #000;
      background: #fff;
      mix-blend-mode: multiply;

      &::before {
        mix-blend-mode: screen;
      }
    } @else {
      color: #fff;
      background: #000;
      mix-blend-mode: lighten;

      &::before {
        mix-blend-mode: multiply;
      }
    }
  }
}

$primary:     rgba(182,25,136,1);
$secondary:   rgba(17,86,190,1);

.munki-font{
  font-size: 18px;
  font-family: 'munki-boy';
  font-size: 2rem;
  font-weight: 500;
}

.clover{
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color:rgba(0,0,0,0.3);
}

a{
  text-decoration:none;
  &:link,&:active,&:visited{
    text-decoration:none;
    color:#000;
  }
  &:focus,&:hover{
    text-decoration:none;
    color:$secondary;
  }
  &.colour{
    &:link,&:active,&:visited{
      text-decoration:none;
      color:#000;
      @include gradient-text('linear-gradient(to right, #7d0000, #1156be)', 'light');
    }
    &:focus,&:hover{
      text-decoration:none;
      color:$secondary;
    }
  }
}

.dark{
  background-color: rgba(0,0,0,0.6);
}

.linear{
    background: linear-gradient(to right, rgba(182,25,136,1), rgba(131,64,173,1), rgba(17,86,190,1));
    &.navbar{
    	background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), linear-gradient(to right, rgba(182,25,136,1), rgba(131,64,173,1), rgba(17,86,190,1));
    }
    &.footer{
      background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.8)), linear-gradient(to right, rgba(182,25,136,1), rgba(131,64,173,1), rgba(17,86,190,1));
      .bottom-footer{
        background: linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.9)), linear-gradient(to right, rgba(182,25,136,1), rgba(131,64,173,1), rgba(17,86,190,1));
      }
    }
}

.linear-transparent{
    background: linear-gradient(to right, rgba(182,25,136,0.5), rgba(131,64,173,0.5), rgba(17,86,190,0.5));
    &.navbar{
      background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), linear-gradient(to right, rgba(182,25,136,0.5), rgba(131,64,173,0.5), rgba(17,86,190,0.5));
    }
    &.footer{
      background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.8)), linear-gradient(to right, rgba(182,25,136,0.5), rgba(131,64,173,0.5), rgba(17,86,190,0.5));
      .bottom-footer{
        background: linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.9)), linear-gradient(to right, rgba(182,25,136,0.5), rgba(131,64,173,0.5), rgba(17,86,190,0.5));
      }
    }
}

.linear-dark{
    background: linear-gradient(to right, rgba(132,132,132,0.5), rgba(126,126,126,0.5), rgba(79,79,79,0.5));
    &.navbar{
      background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), linear-gradient(to right, rgba(132,132,132,0.5), rgba(126,126,126,0.5), rgba(79,79,79,0.5));
    }
    &.footer{
      background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0.8)), linear-gradient(to right, rgba(132,132,132,0.5), rgba(126,126,126,0.5), rgba(79,79,79,0.5));
      .bottom-footer{
        background: linear-gradient(to right, rgba(0,0,0,0.9), rgba(0,0,0,0.9)), linear-gradient(to right, rgba(132,132,132,0.5), rgba(126,126,126,0.5), rgba(79,79,79,0.5));
      }
    }
}


iframe{

  &.map, &.youtube{
    border:none;
    height: 500px;
    width: 100%;
    margin-bottom: 30px;

  }

}

a.youtube-thumb,
a.youtube-link {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.video-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  padding-top: 30px; 
  height: 0; 
  overflow: hidden; 

  &.home{
    margin-top: 30px;
    margin-bottom: 30px;
  }

} 
.video-container iframe, .video-container object, .video-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}

.card-columns{
  column-count: 1;
  @media (min-width: 34em) {
    column-count: 1;
  } 
  @media (min-width: 48em) {
    column-count: 2;
  }
  @media (min-width: 62em) {
    column-count: 4;
  }
  @media (min-width: 75em) {
    column-count: 4;
  }
}

.btn-primary{
   background-color:$primary;
   background-image: linear-gradient(to right, rgba(182,25,136,1), rgba(131,64,173,1));
   border:none;
   color:#fff;
   &:hover,&:active,&focus{
    background-color:darken($primary, 15);
    color:#fff;
   }
   &:link,&:visited{
    box-shadow: 2px $primary;
    color:#fff;
   }
}


.btn-primary:hover {
  color: #fff;
  background-color: darken($primary, 15);
  border:none;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem darken($primary, 15);
  color:#fff;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: $primary;
  border:none;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: darken($primary, 15);
  border:none;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $primary;
  color:#fff;
}

.footer{
  padding-top:60px;
  padding-bottom:0;
  .bottom-footer{
    margin-top:30px;
    padding-top:30px;
    padding-bottom:30px;
    a{
      text-decoration:none;
      &:link,&:active,&:visited{
        text-decoration:none;
        color:#fff;
      }
      &:focus,&:hover{
        text-decoration:none;
        color:$secondary;
      }
    }
  }
}